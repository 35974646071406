import {Component, h} from 'preact';
import style from './style.scss';
import {BASE_URL} from "../../config";
export default class CookiePopup extends Component {

	accept = e => {
		e.preventDefault();
		this.props.cb(true);
	};

	close = e => {
		e.preventDefault();
		this.props.cb(false);
	};

	render (props, state) {
		return <div class={style.cookiePopup}>
			<div class="animated fadeInUp">
				<h3>Cookies!</h3>
				<p>
					La Strega Nocciola utilizza cookie tecnici necessari su questo sito Web per scopi funzionali.
				</p>
				<p>
					Per una migliore esperienza utente, puoi accettare l'uso di cookie opzionali. Questi ricorderanno le tue
					preferenze quando visiti nuovamente il sito Web e analizzeranno il traffico per apportare miglioramenti.
				</p>
				<p class={style.choice}>
					<button onClick={this.accept}><i className={'fal fa-cookie-bite'}></i> Accetto</button>
					{/*<button onClick={ this.close } class="butt secondary">Decline</button>*/}
				</p>
				<p>
					Please read our <a href={BASE_URL + 'cookies'} target="_blank">Cookie Policy page</a> for more information.
				</p>
			</div>
		</div>;
	}

};
