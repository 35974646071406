import { h, Component } from 'preact';
import { Router } from 'preact-router';
import Header from './header';
import Footer from './footer';
import CookiePopup from './cookiepopup';
import { BASE_URL } from "../config";

// Code-splitting is automated for routes
import Home from '../routes/home';
import {loadAnal} from "./utility";
import Cookies from "../routes/cookies";

export default class App extends Component {

	state = { cookiesClosed: false, currentUrlClass: '' };

  cookieAcceptedCB() {
  	loadAnal();
  }

  constructor() {
  	super();

  	this.appWrpEl = false;

  	this.headerCBFun = this.headerCB.bind(this);

  }

  componentDidMount() {
  	this.appWrpEl = document.getElementById('appWrp');
  	if (typeof window !== 'undefined') {
  		if (localStorage.getItem('cookieaccept')) {
  			this.setState({ cookiesClosed: true });
  			this.cookieAcceptedCB();
  		}
  	}
  }

	headerCB = e => {
		e.preventDefault();
		if (this.appWrpEl) {
			if (this.appWrpEl.classList.contains('openMenu'))
				this.appWrpEl.classList.remove('openMenu');
			else
				this.appWrpEl.classList.add('openMenu');
		}
	};

	closePopup() {
		this.setState({ ytData: null });
	}

	acceptCookies (accept) {
		if (typeof window !== 'undefined') {
			if (accept) {
				localStorage.setItem('cookieaccept', true);
				this.cookieAcceptedCB();
			}
		}
		this.setState({ cookiesClosed: true });
	}

	render(props, state) {

		return (<div id="appWrp" className={state.currentUrlClass}>
			<Header cb={this.headerCBFun} u={state.user} isStudio={state.isStudio} />
			<main>
				<Router>
					<Home path={ BASE_URL } default />
					<Cookies path={ BASE_URL + 'cookies' } />
				</Router>
			</main>
			<Footer />
			{ state.cookiesClosed ? '' : <CookiePopup cb={this.acceptCookies.bind(this)} /> }
		</div>);
	}
}
