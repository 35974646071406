import { SITE_URL } from '../config';

export function getUrlVars() {
	let vars = {};
	if (typeof window !== 'undefined') {
		let parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m,key,value) => {
			vars[key] = value;
		});
	}

	return vars;
}

const referrer = (typeof window === 'undefined') ? '' : document.referrer;
const landingPage = (typeof window === 'undefined') ? '' :  window.location.pathname + window.location.search;

export function campaignLogger(additionalData, type) {
	if (typeof window === 'undefined') return;

	const url = window.location.href;

	let subObj = {
		referrer,
		landingPage,
		url
	};

	if (additionalData) {
		subObj = { ...subObj, ...additionalData };
	}

	const logObj = {
		p1: navigator.userAgent,
		p2: JSON.stringify(subObj),
		t: type ? type : 1001
	};

	const xmlhttp = new XMLHttpRequest();
	xmlhttp.withCredentials = true;
	xmlhttp.open('POST', SITE_URL + 'logAnalyticsEvent', true);
	xmlhttp.send(JSON.stringify(logObj));

	console.log('sent event campaign tracker',logObj);
}

export const loadAnal = () => {
	const script = document.createElement('script');
	script.src = 'https://www.googletagmanager.com/gtag/js?id=G-XCF95D0FZ7';
	document.body.appendChild(script);
	script.onload = () => {
		console.log('loading anali');
		window.dataLayer = window.dataLayer || [];
		window.gtag = function() {dataLayer.push(arguments);};
		gtag('js', new Date());
		gtag('config', 'G-XCF95D0FZ7');
	};
};

// Originally inspired by  David Walsh (https://davidwalsh.name/javascript-debounce-function)

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// `wait` milliseconds.
export const debounce = (func, wait) => {
	let timeout;

	// This is the function that is returned and will be executed many times
	// We spread (...args) to capture any number of parameters we want to pass
	return function executedFunction(...args) {

		// The callback function to be executed after
		// the debounce time has elapsed
		// console.log('in debounce')
		const later = () => {
			// null timeout to indicate the debounce ended
			timeout = null;
			// console.log('executing')
			// Execute the callback
			func(...args);
		};
		// This will reset the waiting every function execution.
		// This is the step that prevents the function from
		// being executed because it will never reach the
		// inside of the previous setTimeout
		clearTimeout(timeout);

		// Restart the debounce waiting period.
		// setTimeout returns a truthy value (it differs in web vs Node)
		timeout = setTimeout(later, wait);
	};
};
