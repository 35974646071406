export const BASE_URL = '/';
let staticurl = '/assets/';
let siteurl = 'https://querlo.test/';

console.log('------------->staging env.preact_app_staging', process.env.PREACT_APP_STAGING, 'env.node_env', process.env.NODE_ENV);
if (process.env.PREACT_APP_STAGING === 'stage') {
	staticurl = 'https://staging.lastreganocciola.it/';
	siteurl = 'https://staging.lastreganocciola.it/';
}
else if (process.env.NODE_ENV === 'production') {
	staticurl = '/assets/';
	siteurl = 'https://www.lastreganocciola.it/';
}
console.log('------>', staticurl, siteurl);
export const STATIC_BASE_URL = staticurl;
export const SITE_URL = siteurl;
